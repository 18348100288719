import React from 'react';

import {translate} from '../../../../services';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';
import './_available-substrates-links.scss';


export interface AvailableSubstratesLinksProps {
  allProductsFabricUrl: string;
  allProductsHomeGoodsUrl: string;
  allProductsLivingUrl: string;
  allProductsWallpaperUrl: string;
  colorMapUrl: string;
  conjunction: string;
  homeGoodType?: string;
  isAvailableOnFabric?: boolean;
  isAvailableOnHomeGood?: boolean;
  isAvailableOnWallpaper?: boolean;
  isAvailableOnWallpaperAndHomeGood?: boolean;
  isAvailableOnWallpaperOrHomeGood?: boolean;
  isColorMap: boolean;
  isRotatedFatQuarter?: boolean;
  showFabric?: boolean;
  showHomeGood?: boolean;
  showWallpaper?: boolean;
}

const AvailableSubstratesLinks = ({
  isAvailableOnWallpaper, isAvailableOnHomeGood, isAvailableOnFabric, allProductsFabricUrl, allProductsWallpaperUrl,
  allProductsHomeGoodsUrl, isColorMap, colorMapUrl, showFabric, showWallpaper, showHomeGood, allProductsLivingUrl,
  isAvailableOnWallpaperAndHomeGood, isAvailableOnWallpaperOrHomeGood, conjunction, isRotatedFatQuarter, homeGoodType
}: AvailableSubstratesLinksProps): JSX.Element => (
  <div className='b-available-substrates-links'>
    {(isAvailableOnHomeGood || isAvailableOnWallpaper || isAvailableOnFabric) && <div className='available-substrates-header'>
      {translate('designs.otherSubstrateTextPrefix')}
    </div>}
    <div>
      {showFabric && <LinkWrapper
        className='other-substrate-link-wrapper'
        hrefValue={allProductsFabricUrl}
        contents={translate('designs.otherSubstrateFabric')}
        title={`${translate('designs.otherSubstrateTextPrefix')} ${translate('designs.otherSubstrateFabric')}`} />}
      {showFabric && isAvailableOnWallpaperOrHomeGood && <span className='ampersand'>{conjunction}</span>}
      {showWallpaper && <LinkWrapper
        force={true}
        className='other-substrate-link-wrapper'
        hrefValue={allProductsWallpaperUrl}
        contents={translate('designs.otherSubstrateWallpaper')}
        title={`${translate('designs.otherSubstrateTextPrefix')} ${translate('designs.otherSubstrateWallpaper')}`} />}
      {isAvailableOnWallpaperAndHomeGood && <span className='ampersand'>{conjunction}</span>}
      {showHomeGood && <LinkWrapper
        className='other-substrate-link-wrapper'
        hrefValue={allProductsHomeGoodsUrl}
        contents={translate('designs.otherSubstrateHomeDecor')}
        title={`${translate('designs.otherSubstrateTextPrefix')} ${translate('designs.otherSubstrateHomeDecor')}`} />}
      {showFabric && isRotatedFatQuarter && <span className='ampersand'>{conjunction}</span>}
      {showFabric && isRotatedFatQuarter && <LinkWrapper
        className='other-substrate-link-wrapper'
        hrefValue={allProductsHomeGoodsUrl}
        contents={translate('designs.teaTowels')}
        title={`${translate('designs.teaTowels')} ${translate('designs.otherSubstrateHomeDecor')}`} />}
      {isColorMap && <LinkWrapper
        className='other-substrate-link-wrapper'
        hrefValue={colorMapUrl}
        contents={translate('designs.otherSubstrateWallpaper')}
        title={`${translate('designs.otherSubstrateTextPrefix')} ${translate('designs.otherSubstrateWallpaper')}`}/>}
    </div>
  </div>
);

export default AvailableSubstratesLinks;
